import React from "react"
import { graphql, navigate, StaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css"
import listEllipse from "../../../static/images/list-image-ellipse.svg"
import "./home.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query HomeQuery {
        allContentfulBlog(
          limit: 4
          sort: { fields: [createdAt], order: DESC }
          filter: { node_locale: { eq: "en-US" }, home: { eq: true } }
        ) {
          edges {
            node {
              id
              slug
              title
              date
              featuredImage {
                fluid(maxWidth: 394, quality: 80) {
                  src
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Container fluid className="px-0 news-container" id="news">
        <Row>
          <h2
            className="section-heading w-100 mb-2"
            style={{
              color: "var(--textNormal)",
              transition: "color 0.2s ease-out, background 0.2s ease-out",
            }}
          >
            Рекомендуемые новости
          </h2>
          {data.allContentfulBlog.edges.map(edge => (
            <Col
              className="news-item"
              sm={6}
              md={6}
              lg={3}
              xl={3}
              key={edge.node.id}
            >
              <hr />
              <p
                className="news-date"
                style={{
                  color: "var(--textNormal)",
                  transition: "color 0.2s ease-out, background 0.2s ease-out",
                }}
              >
                <img className="list-ellipse mr-1" src={listEllipse} />
                {edge.node.date}
              </p>
              <p
                className="news-title"
                style={{
                  color: "var(--textNormal)",
                  transition: "color 0.2s ease-out, background 0.2s ease-out",
                }}
              >
                {edge.node.title}
              </p>
              <div className="mb-0">
                <Button
                  variant="link"
                  onClick={() => navigate(`/blog/${edge.node.slug}`)}
                  className="read-more-btn"
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  <u>Читать дальше</u>
                </Button>
              </div>

              <Image alt="" src={edge.node.featuredImage.fluid.src} fluid />
            </Col>
          ))}
          <br />
          <Button
            style={{
              color: "var(--textNormal)",
              transition: "color 0.2s ease-out, background 0.2s ease-out",
            }}
            variant="link"
            onClick={() => navigate(`/blog`)}
          >
            Смотреть все
          </Button>
        </Row>
      </Container>
    )}
  />
)
