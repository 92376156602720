import React from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { window } from "browser-monads"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import logo from "../../../static/images/logo.svg"
import logoDark from "../../../static/images/logo-light-mode.svg"
import logoSmall from "../../../static/images/logo-small.svg"
import menuIcon from "../../../static/images/menu.svg"
import "./index-page-navbar.scss"
import styled from "styled-components"

export default class IndexPageNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
      scrollPos: 0,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    const { scrollPos } = this.state
    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > scrollPos,
    })
  }
  render() {
    return (
      <Transition>
        <Navbar
          className={
            this.state.show
              ? "active p-0 d-none d-xl-flex"
              : "hidden p-0 d-none d-xl-flex"
          }
          expand="xl"
          fixed="top"
        >
          <Link className="p-0" to="/">
            <img
              src={logo}
              className="d-inline-block align-top d-xs-none d-sm-none d-md-block d-lg-block d-xl-block logo-telis logo-dark-mode"
              alt="Логотип Телис"
            />
            <img
              src={logoDark}
              className="d-inline-block align-top d-xs-none d-sm-none d-md-block d-lg-block d-xl-block logo-telis logo-light-mode"
              alt="Логотип Телис"
            />
            <img
              src={logoSmall}
              className="d-inline-block align-top d-xs-block d-sm-block d-md-none d-lg-none d-xl-none logo-telis"
              alt="Логотип Телис"
            />
          </Link>
          <Navbar.Toggle aria-controls="telis-nav" className="p-0">
            <span>
              <img src={menuIcon} />
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="telis-nav">
            <Nav className="mx-auto align-items-center">
              {/* <Link to="/#services" className="text-uppercase nav-link">
                Решения и услуги
              </Link> */}
              <Link to="/#news" className="text-uppercase nav-link">
                Новости
              </Link>
              <Link to="/blog" className="text-uppercase nav-link">
                Блог
              </Link>
              {/*  <Link to="#" className="text-uppercase text-white nav-link">
                О нас
              </Link> */}
              <a
                href="mailto:support@telisit.ru"
                className="text-uppercase nav-link"
              >
                Поддержка
              </a>
              <Link to="/contacts" className="text-uppercase nav-link">
                Контакты
              </Link>
            </Nav>
            <Nav className="navbar-right">
              <a
                href="tel:+7-812-941-4837"
                className="text-uppercase text-white nav-link"
              >
                <FontAwesomeIcon className="mr-1 fa-lg" icon={faPhoneAlt} />{" "}
                <u>8 (812) 941-48-37</u>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Transition>
    )
  }
}
const Transition = styled.div`
  .active {
    visibility: visible;
    transition: all 250ms ease-in;
  }
  .hidden {
    visibility: hidden;
    transition: all 250ms ease-out;
    transform: translate(0, -100%);
  }
`
