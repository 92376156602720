import React from "react"
import { Link } from "gatsby"

import { window } from "browser-monads"

import "./sidebar-right.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebook,
  faInstagram,
  faVk,
} from "@fortawesome/free-brands-svg-icons"

class SidebarRight extends React.Component {
  render() {
    return (
      <div className="contact-us-container">
        <div className="vertical-contact-us-text">
          {/*     <i className="material-icons pb-2 text-light">
              chat_bubble_outline
            </i> */}
          <a
            href="https://www.facebook.com/telisit.ru/"
            rel="noopener noreferrer nofollow"
            target="_blank"
            style={{
              color: "var(--textNormal)",
              transition: "color 0.2s ease-out, background 0.2s ease-out",
            }}
          >
            <FontAwesomeIcon
              className="fa-rotate-180 mb-3 fa-lg"
              icon={faFacebook}
            />
          </a>

          <a
            href="https://www.instagram.com/teliscompany/"
            rel="noopener noreferrer nofollow"
            target="_blank"
            style={{
              color: "var(--textNormal)",
              transition: "color 0.2s ease-out, background 0.2s ease-out",
            }}
          >
            <FontAwesomeIcon
              className="fa-rotate-180 mb-3 fa-lg"
              icon={faInstagram}
            />
          </a>

          <a
            href="https://vk.com/telisit/"
            rel="noopener noreferrer nofollow"
            target="_blank"
            style={{
              color: "var(--textNormal)",
              transition: "color 0.2s ease-out, background 0.2s ease-out",
            }}
          >
            <FontAwesomeIcon className="fa-rotate-180 mb-5 fa-lg" icon={faVk} />
          </a>

          <Link to="/contacts">
            <p
              className="d-inline"
              style={{
                color: "var(--textNormal)",
                transition: "color 0.2s ease-out, background 0.2s ease-out",
              }}
            >
              <FontAwesomeIcon
                className="fa-rotate-90 mb-2 fa-lg"
                icon={faCommentAlt}
              />
              Связаться с нами
            </p>
          </Link>
        </div>
      </div>
    )
  }
}

export default SidebarRight
