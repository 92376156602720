import React from "react"
import Image from "react-bootstrap/Image"
import Container from "react-bootstrap/Container"
import necImg from "../../images/nec_eng.png"
import oracleImg from "../../images/oracle_gold_partner.png"
import netnumberImg from "../../images/netnumber.png"
import rostelecomImg from "../../images/rostele_logo_rus_whi.png"
import beelineImg from "../../images/logo-kar-tel-beeline-kz.png"
import itecoImg from "../../images/i-teco-client.png"
import "./partners-home.scss"

export default function partners() {
  return (
    <>
      <Container fluid id="partners-clients">
        <h2
          className="partners-section w-100 px-0"
          style={{
            color: "var(--textNormal)",
            transition: "color 0.2s ease-out, background 0.2s ease-out",
          }}
        >
          Партнёры и клиенты
        </h2>
        <div className="partners-flex-container">
          <div className="partners-flex-cell">
            <div className="partners-flex-item">
              <Image fluid src={necImg} alt="NEC" />
            </div>
          </div>
          <div className="partners-flex-cell">
            <div className="partners-flex-item">
              <Image fluid src={oracleImg} alt="Oracle" />
            </div>
          </div>
          <div className="partners-flex-cell">
            <div className="partners-flex-item">
              <Image fluid src={netnumberImg} alt="NetNumber" />
            </div>
          </div>
          <div className="partners-flex-cell">
            <div className="partners-flex-item">
              <Image fluid src={rostelecomImg} alt="Ростелеком" />
            </div>
          </div>
          <div className="partners-flex-cell">
            <div className="partners-flex-item">
              <Image fluid src={beelineImg} alt="ТОО «КАР-ТЕЛ»" />
            </div>
          </div>
          <div className="partners-flex-cell">
            <div className="partners-flex-item">
              <Image fluid src={itecoImg} alt="АЙ-ТЕКО" />
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
