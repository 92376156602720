import React, { Component } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Image from "react-bootstrap/Image"
import Carousel from "react-bootstrap/Carousel"
import "bootstrap/dist/css/bootstrap.min.css"
import telisBigLogo from "../../images/logo-telis-dark-big.png"
import "./hero-home.scss"

export default class indexHero extends Component {
  render() {
    return (
      <>
        <Container fluid className="hero-container px-0" id="services">
          <hr className="top-line" />
          <hr className="bottom-line" />
          <Image className="telis-big-logo" src={telisBigLogo} fluid />
          <Carousel slide interval={5000} pauseOnHover={false} controls={false}>
            <Carousel.Item>
              <Carousel.Caption>
                <h3
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  Проектирование и адаптация
                </h3>
                <p
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  информационных систем
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h3
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  Диспетчеризация и автоматизация
                </h3>
                <p
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  инженерных систем
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h3
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  Обслуживание и ремонт
                </h3>
                <p
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  телекоммуникационного оборудования
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h3
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  Внедрение / интеграция
                </h3>
                <p
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  телекоммуникационных систем
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h3
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  Поставка
                </h3>
                <p
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  оборудования и ПО
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Carousel.Caption>
                <h3
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  Сервисная и гарантийная поддержка
                </h3>
                <p
                  style={{
                    color: "var(--textNormal)",
                    transition: "color 0.2s ease-out, background 0.2s ease-out",
                  }}
                >
                  24 / 7 / 365
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Container>
      </>
    )
  }
}
