import React from "react"
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"

import IndexPageNavbar from "../components/index-page-navbar"
import IndexPageNavbarWithOverlay from "../components/index-page-navbar-with-overlay"
import Home from "../components/home"
import HeroHome from "../components/hero-home"
import Sidebar from "../components/sidebar-right"
import Layout from "../components/layout"
import Partners from "../components/partners-home"
import SEO from "../components/seo"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false
if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}
const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
      />
      <meta name="yandex-verification" content="8e2ab03e9f481611" />
    </Helmet>
    <SEO title="Главная страница" />

    <IndexPageNavbar />
    <IndexPageNavbarWithOverlay />
    <Sidebar />
    <HeroHome />
    <Partners />
    <Home />
  </Layout>
)

export default IndexPage
