import React from "react"
import { Link } from "gatsby"

import { window } from "browser-monads"

import { slide as Menu } from "react-burger-menu"
import logo from "../../../static/images/logo.svg"
import logoDark from "../../../static/images/logo-light-mode.svg"
import logoDarkSmall from "../../../static/images/logo-dark-small.svg"
import logoSmall from "../../../static/images/logo-small.svg"
import "./index-page-navbar-with-overlay.scss"

class IndexPageNavbarWithOverlay extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  render() {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <>
        <Link to="/" className="home-link-dark-mode">
          <img className="site-logo-index logo-telis" src={logo} />
          <img className="site-logo-small-index logo-telis" src={logoSmall} />
        </Link>
        <Link to="/" className="home-link-light-mode">
          <img className="site-logo-index logo-telis" src={logoDark} />
          <img
            className="site-logo-small-index logo-telis"
            src={logoDarkSmall}
          />
        </Link>

        <Menu
          width={"100%"}
          customCrossIcon={
            <img src={require("../../../static/images/close.svg")} />
          }
          customBurgerIcon={
            <img src={require("../../../static/images/menu.svg")} />
          }
          right
        >
          {/* <Link className="h2 menu-item text-right" to="/#services">
            Решения и услуги
          </Link>
          <Link className="h2 menu-item text-right" to="/#news">
            Новости
          </Link> */}
          <Link className="h2 menu-item text-right" to="/blog">
            Блог
          </Link>
          {/* <Link className="h2 menu-item text-right" to="/">
            О нас
          </Link> */}
          <a
            className="h2 menu-item text-right"
            href="mailto:support@telisit.ru"
          >
            Поддержка
          </a>
          <Link className="h2 menu-item text-right" to="/contacts">
            Контакты
          </Link>
        </Menu>
      </>
    )
  }
}

export default IndexPageNavbarWithOverlay
